<template>
    <div class="text_style">
        Terms Of Service and Privacy Policy Last modified: 12 Nov 2021<br>
        INTRODUCTION<br>
        Welcome to NFT MMM! Your use of NFT MMM (“services” or “our services”)is subject to these Terms.<br>
        Compliance with these Terms<br>
        These Terms apply to you if you are a user of our services anywhere in the world other than the People’s Republic of China (and you are not a citizen of the People’s Republic of China). If you are: (i) a user of our services in the People’s Republic of China; (ii) a citizen of the People’s Republic of China using our services anywhere in the world; or (iii) a Chinese-incorporated company using our services anywhere in the world, please refer to the Terms of Service (PRC Users) for the terms that apply to you in your use of our services.<br>
        You must comply with these Terms in your use of our services and only use our services as permitted by applicable laws and regulations, wherever you may be when you use them. You must review these Terms and our policies and instructions to understand how you can and cannot use our services. If you do not agree to these Terms, you must not use our services.<br>
        Contracting Entity<br>
        By using our services, you are agreeing to be bound by these Terms of Service between you and App Board  Ltd., a Hong Kong corporation located at 1/F Union Industrial Building, 116 Wai Yip Street, Kwun Tong, Hong Kong (“we”, “our” and “us”) (these“Terms”).<br>
        Other terms in relation to these Terms<br>
        If you are under the age of 13, you must not use our services. If you are between the ages of 13 and 18 (or the relevant age in your jurisdiction where you are considered a minor), your parent or guardian must agree to these Terms (both for themselves and on your behalf) before you can use our services.<br>
        If you are using our services on behalf of a company, partnership, association, government or other organization (your “ Organization”), you warrant that you are authorized to do so and that you are authorized to bind your Organization to these Terms and in such circumstances “you” will include your Organization.<br>
        We may translate these Terms into multiple languages, and in the event there is any difference between the English version and any other language version of these Terms, the English version will apply (to the extent permitted by applicable law).<br>
        ADDITIONAL POLICIES<br>
        The following are additional policies that you must comply with in using our services:<br>
        NFT MMM Privacy Policy– which sets out how we collect, store and use your personal information; NFT MMM Acceptable Use Policy – which sets out rules of good behave our applicable to your use of our services; and NFT MMM Cookies Policy – which sets out our policy on the usage of cookies Additional country-specific terms<br>
        If you are a citizen or a habitual resident of the following countries, the following country-specific terms will also apply to your use of our services:<br>
        USA; and Australia.Inconsistencies<br>
        To the extent that any additional terms of service or policies are different from these Terms, the relevant additional terms of service or additional policies will apply, except that nothing in those additional terms of service or policies will (unless expressly indicated otherwise in those additional terms of service or policies) amend the following sections of these Terms (which will apply in the event there is any difference):<br>
        “Changes to these Terms and our services”;“Our intellectual property rights and software”; and“Liability for our services and software”.However, to the extent that any country-specific terms differ from these Terms or any additional terms of service or policies, the relevant country-specific terms will apply, regardless of which section of these Terms is in question.<br>
        CHANGES TO TERMS AND OUR SERVICES<br>
        We may make changes to these Terms over time, so please come back and review them.<br>
        In addition, as our services and user experience are constantly evolving, we may from time to time (and to the extent permitted by applicable law) add, change or remove features from our services (including in relation to whether a service is free of charge or not), or suspend or terminate a service altogether.<br>
        Where we consider that any changes to these Terms or our services are reasonably material, we will notify you (via http://www.ABQQS.com , direct communication to you, or other means), prior to the change becoming effective. By continuing to use our services after we make any changes to these Terms or our services, with or without notice from us, you are agreeing to be bound by these revised Terms.<br>
        YOUR ACCOUNT<br>
        You may need to create an account with us in order to access and use some of our services.<br>
        Any account you open with us is personal to you and you are prohibited from gifting, lending, transferring or otherwise permitting any other person to access or use your account. Your account name, user ID and other identifiers you adopt within our services remains our property and we can disable, reclaim and reuse these once your account is terminated or deactivated for whatever reason by either you or us.<br>
        You are responsible for: (i) safeguarding any passwords used to access your account and our services, and (ii) all use of our services under your account. You must promptly notify us at yelq@abqqs.com if you know or suspect that your password or account has been compromised.<br>
        Account deactivation<br>
        Our services may offer methods for deactivating your service-specific account – please refer to the relevant service for further guidance.<br>
        PAYMENTS<br>
        You may, from time to time, make payments to us as part of your use of our services (including for the provision of our services or provision of certain additional features within our services). Our services-specific terms may set out further terms applying to such payments (including in relation to refunds (if any), billing arrangements and any consequences of failing to make timely payments). You must comply with all such terms in relation to your payments to us.<br>
        YOUR CONTENT<br>
        When you submit, upload, transmit or display any data, information, media or other content in connection with your use of our services (“ Your Content”), you understand and agree that:<br>
        you will continue to own and be responsible for Your Content;we will not sell Your Content to any third party;our use of Your Content will be subject to our NFT MMM Privacy Policy;you are giving us and our affiliate companies the right to use Your Content (with no fees or charges payable by us to you) solely for the purposes of providing, promoting, developing and trying to improve our services, including new services that we may provide in the future;in using Your Content for these purposes, we and our affiliate companies may copy, reproduce, host, store, process, adapt, modify, translate, perform, distribute and publish Your Content worldwide in all media and by all distribution methods, including those that are developed in the future, provided that they are incorporated into our services which you use; andwe may share Your Content with third parties that we work with to help provide, promote, develop and improve our services, but these third parties(other than our affiliate companies) will not make any separate use of Your Content for their own purposes (i.e. for any purposes that are not related to our services).In addition, you agree that we and our affiliate companies (subject to these Terms, our NFT MMM Privacy Policy and applicable laws and regulations):<br>
        are allowed to retain and continue to use Your Content after you stop using our services- for example, where you have shared Your Content with other users of our services; andmay be required to retain or disclose Your Content in order to comply with applicable laws or regulations, or in order to comply with a court order, subpoena or other legal process, or we and our affiliate companies may disclose Your Content in response to a lawful request by a government authority, law enforcement agency or similar body (whether situated in your jurisdiction or elsewhere). You also agree that we may need to disclose Your Content in order to enforce these Terms, protect our rights, property or safety, or the rights, property or safety of our affiliate companies or other users of our services.You understand that even if you seek to delete Your Content from our services, it may as a technical and administrative matter take some time or not be possible to achieve this.<br>
        Responsibility for Your Content<br>
        You are solely responsible for Your Content and we recommend that you keep a back-up copy of it at all times. You must at all times ensure that: (i) you have the rights required to submit, transmit or display Your Content, and to grant us the rights as set out in these Terms; and (ii) Your Content (and our use of Your Content in accordance with these Terms) does not infringe or violate the rights of any person or otherwise contravene any applicable laws or regulations.<br>
        THIRD PARTY CONTENT AND SERVICES<br>
        We are not responsible for and we do not endorse, support or guarantee the lawfulness, accuracy or reliability of any content submitted to, transmitted or displayed by or linked by our services, including content provided by other users of our services or by our advertisers. You acknowledge and agree that by using our services you may be exposed to content which is inaccurate, misleading, defamatory, offensive or unlawful. Any reliance on or use of any content on or accessible from our services by you is at your own risk. Your use of our services does not give you any rights in or to any content you may access or obtain in connection with your use of our services.<br>
        We also do not guarantee the quality, reliability or suitability of any third party services provided, made available or linked through our services and we will bear no responsibility for such third party services. If you access third party services through our services, you must comply with any terms and conditions applicable to those services.<br>
        We may review (but make no commitment to review) content or third party services made available through our services to determine whether or not they comply with our policies, applicable laws and regulations or are otherwise objectionable. We may remove or refuse to make available or link to certain content or third party services if they infringe intellectual property rights, are obscene, defamatory or abusive, violate any rights or pose any risk to the security or performance of our services.<br>
        There may be, from time to time, third party content and services on our services that are subject to further terms, including terms from the relevant third party that originally produced such content and services (for instance, the news agency that was responsible for writing a news article that then appears on our news service). In such cases, you agree to comply with any such further terms and conditions as notified to you.<br>
        If you have any concerns regarding any content on or any other aspects of our services, please contact yelq@abqqs.com .<br>
        ADVERTISING CONTENT ON OUR SERVICES<br>
        Our services may include advertising or commercial content. You agree that we are allowed to integrate advertising or commercial content into our services and that (where reasonably practicable) we will identify paid services and communications. You also agree that, as explained in more detail in our NFT MMM Privacy Policy, we use targeted advertising to try to make advertising more relevant and valuable to you.<br>
        DIRECT MARKETING<br>
        We may use your information for the purpose of sending you advertising or direct marketing (whether by messaging within our services, by email or by other means) that offer or advertise products and services of ours and/or selected third parties. Such products and services include:<br>
        products and services of ours (or our affiliate and subsidiary companies and joint venture partners), including services in relation to messaging, online media and advertising, interactive entertainment, social networking, e-commerce, news and information, payment, internet search, location and mapping, applications, data management, and other related software and services (“Internet Services”); andthird party providers of Internet Services and products and services relating to dining, food and beverages, sports, music, film, television, live performances and other arts and entertainment, books, magazines and other publications, clothing and accessories, jewelry , cosmetics, personal health and hygiene, electronics, collectibles, housewares, appliances, home decoration and furnishings, pets, automobiles, hotels, transport and travel, banking, insurance and financial services, loyalty and reward programs and other products and services that we think may be relevant to you.If required by applicable laws, we will honor your request for us to not use your personal information for the direct marketing purposes noted above. If you wish to make such a request, please: (i) notify our Privacy Officer using the contact details as set out in the NFT MMM Privacy Policy; (ii) follow the relevant instructions on our marketing messages; or (iii) if available, follow the instructions as set out in certain service-specific guidance (in relation to the relevant service).<br>
        Please note that we will not share your personal information with advertisers unless you have given us your consent to do so. We do, however, share non-personal information with advertisers for the purposes of offering you advertising that is more relevant to you.<br>
        OUR INTELLECTUAL PROPERTY RIGHTS<br>
        All intellectual property rights in or to our services and software (including any future updates, upgrades and new versions) will continue to belong to us and our licensors. Except as expressly provided in these Terms, you have no right to use our intellectual property rights. In particular, you have no right to use our trade marks or product names (for example, “NFT MMM” or “ARE FILM”), logos, domain names or other distinctive brand features without our prior written consent. Any comments or suggestions you may provide regarding our services are entirely voluntary and we will be free to use these comments and suggestions at our discretion without any payment or other obligation to you.<br>
        Where our services involve you downloading and using any software from us, we grant you a limited, personal, non-exclusive, non-sublicensable, non-transferrable, royalty-free and revocable licence to use the software in order to use our services in accordance with these Terms (including any specific technical requirements that relate to the software or its use on your particular device). Please note that these licence terms may be supplemented by terms and conditions applicable to the specific software.<br>
        You may not copy, modify, reverse compile, reverse engineer or extract source codes from our software, except to the extent that we may not prohibit you from doing so under applicable laws or regulations or you have our prior written consent to do so. Where applicable laws or regulations entitle you to reverse compile or extract source codes from our software, you will first contact us to request the information you need.<br>
        We may from time to time provide updates to our software. Such updates may occur automatically or manually. Please note that our services may not operate properly or at all if upgrades or new versions are not installed by you. We do not guarantee that we will make any updates available for any of our software, or that such updates will continue to support your device or system.<br>
        Third party software<br>
        The “Third Party Content and Services” section above applies to any software supplied by third parties (including software, plug-ins, tools, codecs, data and content within such software) for use in connection with, or incorporated within, our services ("Third Party Software"), including your use of such Third Party Software.<br>
        In addition to that section, please note that we are not responsible for and we do not endorse, support or guarantee the quality, reliability or suitability of any such Third Party Software. Any reliance on or use of such Third Party Software is at your own risk.<br>
        You must comply with any additional terms and conditions applicable to any such Third Party Software. We will notify you of any such additional terms and conditions within our services, within an Appendix to these Terms and/or in another manner.<br>
        We do not provide any technical support for any Third Party Software. Please contact the relevant supplying third party for such technical support.<br>
        OPEN SOURCE SOFTWARE<br>
        Further to the "Third Party Software" section above, our services may use software that is subject to “open source” licences (the “Open Source Software”). Where we use such Open Source Software, please note that:<br>
        there may be provisions in the Open Source Software's licence that expressly override these Terms, in which case such provisions shall prevail to the extent of any conflict with these Terms; and we will credit the relevant Open Source Software (including notifying you of the relevant Open Source Software terms) within our services, within an Appendix to these Terms and/or in another manner.<br>
        WARRANTY AND DISCLAIMER<br>
        We warrant to you that we will provide our services using reasonable care and skill.<br>
        APART FROM THIS WARRANTY, TO THE EXTENT PERMITTED BY APPLICABLE LAWS AND REGULATION, ALL OF OUR SERVICES AND SOFTWARE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND NEITHER US NOR ANY OF OUR AFFILIATE COMPANIES MAKE ANY REPRESENTATION OR WARRANTY OR GIVES ANY UNDERTAKING IN RELATION TO OUR SERVICES, OUR SOFTWARE OR ANY CONTENT SUBMITTED, TRANSMITTED OR DISPLAYED BY OUR SERVICES, INCLUDING: (I) ANY REPRESENTATION, WARRANTY OR UNDERTAKING THAT OUR SERVICES OR SOFTWARE WILL BE UNINTERRUPTED, SECURE OR ERROR-FREE OR FREE FROM VIRUSES; (II) THAT OUR SERVICES OR SOFTWARE WILL BE COMPATIBLE WITH YOUR DEVICE; OR (III) THAT OUR SERVICES OR SOFTWARE WILL BE OF MERCHANTABLE QUALITY, FIT FOR A PARTICULAR PURPOSE OR NOT INFRINGE THE INTELLECTUAL PROPERTY RIGHTS OF ANY PERSON. TO THE EXTENT PERMITTED BY APPLICABLE LAWS AND REGULATION, YOU WAIVE ANY AND ALL IMPLIED REPRESENTATIONS, WARRANTIES AND UNDERTAKINGS.<br>
        LIABILITY FOR OUR SERVICES AND SOFTWARE<br>
        TO THE EXTENT PERMITTED BY APPLICABLE LAWS AND REGULATIONS, THE TOTAL AGGREGATE LIABILITY OF US AND OUR AFFILIATE COMPANIES FOR ALL CLAIMS IN CONNECTION WITH THESE TERMS, THE GENERAL END USER LICENCE AGREEMENT OR OUR SERVICES OR SOFTWARE, ARISING OUT OF ANY CIRCUMSTANCES, WILL BE LIMITED TO THE GREATER OF THE FOLLOWING AMOUNTS: (I) THE AMOUNT THAT YOU HAVE PAID TO US FOR YOUR USE OF THE SPECIFIC SERVICE OR SOFTWARE TO WHICH THE CLAIM RELATES IN THE 6 MONTHS IMMEDIATELY PRECEDING THE DATE OF THE MOST RECENT CLAIM; AND (II) USD100.<br>
        TO THE EXTENT PERMITTED BY APPLICABLE LAWS AND REGULATIONS, IN NO EVENT WILL WE OR ANY OF OUR AFFILIATE COMPANIES BE LIABLE IN CONNECTION WITH THESE TERMS, THE GENERAL END USER LICENCE AGREEMENT OR OUR SERVICES OR SOFTWARE FOR ANY DAMAGES CAUSED BY: (I) ANY NATURAL DISASTER SUCH AS FLOODS, EARTHQUAKES OR EPIDEMICS; (II) ANY SOCIAL EVENT SUCH AS WARS, RIOTS OR GOVERNMENT ACTIONS; (III) ANY COMPUTER VIRUS, TROJAN HORSE OR OTHER DAMAGE CAUSED BY MALWARE OR HACKERS; (IV) ANY MALFUNCTION OR FAILURE OF OUR OR YOUR SOFTWARE, SYSTEM, HARDWARE OR CONNECTIVITY; (V) IMPROPER OR UNAUTHORISED USE OF OUR SERVICES OR SOFTWARE; (VI) YOUR USE OF OUR SERVICES OR SOFTWARE IN BREACH OF THESE TERMS OR THE GENERAL END USER LICENCE AGREEMENT; OR (VII) ANY REASONS BEYOND OUR REASONABLE CONTROL OR PREDICTABILITY. NOR WILL WE, TO THE EXTENT PERMITTED BY APPLICABLE LAWS AND REGULATIONS, BE LIABLE UNDER ANY CIRCUMSTANCES FOR ANY INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES OR FOR ANY LOSS OF BUSINESS, REVENUES, PROFITS, GOODWILL, CONTENT OR DATA.<br>
        Nothing in these Terms limits or excludes any of the following liabilities, except to the extent that such liabilities may be waived, limited or excludedunder applicable laws and regulations:<br>
        any liability for death or personal injury;any liability for gross negligence or wilful misconduct; orany other liability to the extent that such liability cannot be waived, limited or excluded under applicable laws and regulations.NOTWITHSTANDING ANY OTHER PROVISIONS OF THESE TERMS, NOTHING IN THESE TERMS LIMITS OR EXCLUDES ANY OF YOUR STATUTORY RIGHTS IN YOUR JURISDICTION (INCLUDING ANY RIGHTS UNDER APPLICABLE CONSUMER PROTECTION REGULATION), TO THE EXTENT THESE MAY NOT BE EXCLUDED OR WAIVED UNDER APPLICABLE LAWS AND REGULATIONS.<br>
        YOU AGREE THAT YOU (AND YOUR ORGANISATION, IF YOU ARE USING OUR SERVICES OR SOFTWARE ON BEHALF OF SUCH ORGANISATION) INDEMNIFY US, OUR PARTNERS AND OUR AFFILIATE COMPANIES FROM AND AGAINST ANY CLAIM, SUIT, ACTION, DEMAND, DAMAGE, DEBT, LOSS, COST, EXPENSE (INCLUDING LITIGATION COSTS AND ATTORNEYS’ FEES) AND LIABILITY ARISING FROM: (I) YOUR USE OF OUR SERVICES OR SOFTWARE; OR (II) YOUR BREACH OF THESE TERMS OR ANY TERMS OF THE GENERAL END USER LICENCE AGREEMENT.<br>
        Please note that we are not responsible for any third party charges you incur (including any charges from your internet and telecommunication services providers) in relation to or arising from your use of our services or software.<br>
        TERMINATION<br>
        These Terms will apply to your use of our services until access to the relevant services is terminated by either you or us.<br>
        We may suspend or terminate your access to your account or any or all of our services: (i) if we reasonably believe that you have breached these Terms;(ii) if your use of our services creates risk for us or for other users of our services, gives rise to a threat of potential third party claims against us or is potentially damaging to our reputation; (iii) if you fail to use our services for a prolonged period; or (iv) for any other reason. Where reasonably practicable, we will give you notice of any suspension or termination.<br>
        Retention and back-up of Your Content<br>
        Following termination of these Terms, we will only retain and use Your Content in accordance with these Terms, in particular the NFT MMM Privacy Policy. Where we suspend or terminate a service, or where your access to our services is terminated by you or us, we do not guarantee that we will be able to return any of Your Content back to you and we may permanently delete Your Content without notice to you at any time after termination. Please ensure that you regularly back up Your Content.<br>
        GENERAL<br>
        These Terms are the entire agreement between you and us in relation to our services. You agree that you will have no claim against us for any statement which is not explicitly set out in these Terms. The invalidity of any provision of these Terms (or parts of any provision) will not affect the validity or enforceability of any other provision (or the remaining parts of that provision). If a court holds that we cannot enforce any part of these Terms as drafted, we may replace those terms with similar terms to the extent enforceable under applicable law, without changing the remaining terms of these Terms. No delay in enforcing any provision of these Terms will be construed to be a waiver of any rights under that provision. Any rights and obligations under these Terms which by their nature should survive, including but not limited to any obligations in relation to the liability of, or indemnities (if any)given by, the respective parties, will remain in effect after termination or expiration of these Terms.<br>
        No person other than you and us will have any right to enforce these Terms against any person, and you may not delegate, assign or transfer these Terms or any rights or obligations under these Terms, by operation of law or otherwise without our consent. We may freely assign, transfer or sub-contract these Terms or our rights and obligations under these Terms, in whole or in part, without your prior consent or notice. You acknowledge and agree that in no event will our partners or affiliate companies have any liability under these Terms.<br>
        GOVERNING LAW AND DISPUTE RESOLUTION<br>
        Except to the extent that: (i) any applicable additional terms incorporated into these Terms provide differently, or (ii) the applicable laws and regulations of your jurisdiction mandate otherwise (for instance, you may have statutory rights in your jurisdiction in relation to bringing or defending claims in a local court (including small claims court (or similar court), these Terms and any dispute or claim arising out of or in connection with these Terms will be governed by the law of the Hong Kong Special Administrative Region.<br>
        Any dispute, controversy or claim (whether in contract, tort or otherwise) arising out of, relating to, or in connection with these Terms, including their existence, validity, interpretation, performance, breach or termination, will be referred to and finally resolved by arbitration administered by the Hong Kong International Arbitration Centre under the Hong Kong International Arbitration Centre Administered Arbitration rules in force when the Notice of Arbitration is submitted. The seat of the arbitration will be Hong Kong. There will be one arbitrator only. The arbitration proceedings will be conducted in English.<br>
        NFT MMM TERMS OF SERVICE (USA-SPECIFIC TERMS)<br>
        If you are a user of our services in the United States of America, the below terms are incorporated into these Terms, and override these Terms to the extent of any inconsistency.<br>
        If you are a California resident, you agree to waive California Civil Code Section 1542, and any similar provision in any other jurisdiction (if you are a resident of such other jurisdiction), which states: "A general release does not extend to claims which the creditor does not know or suspect to exist in his favor at the time of executing the release, which, if known by him must have materially affected his settlement with the debtor.”<br>
        EACH OF THE PARTIES HERETO IRREVOCABLY WAIVES ANY AND ALL RIGHT TO TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION IN ANY LEGAL PROCEEDING ARISING OUT OFOR RELATING TO THESE TERMS OR THE TRANSACTIONS CONTEMPLATED HEREBY.<br>
        NFT MMM TERMS OF SERVICE (AUSTRALIA-SPECIFIC TERMS)<br>
        If you are a user of our services in Australia, the below terms are incorporated into these Terms, and override these Terms to the extent of any inconsistency.<br>
        All express or implied guarantees, warranties, representations, or other terms and conditions relating to these Terms or their subject matter, not contained in these Terms, are excluded from these Terms to the maximum extent permitted by law.<br>
        Nothing in these Terms excludes, restricts or modifies any guarantee, warranty, term or condition, right or remedy implied or imposed by any applicable law which cannot lawfully be excluded, restricted or modified.<br>
        If any guarantee, condition, warranty or term is implied or imposed by any applicable law and cannot be excluded (a “Non-Excludable Provision”), and we are able to limit your remedy for a breach of the Non-Excludable Provision, then our liability for breach of the Non-Excludable Provision is limited to one or more of the following at our option:<br>
        in the case of goods, the replacement of the goods or the supply of equivalent goods, the repair of the goods, the payment of the cost of replacing the goods or of acquiring equivalent goods, or the payment of the cost of having the goods repaired; orin the case of services, the supplying of the services again, or the payment of the cost of having the services supplied again.NFT MMM Privacy Policy Last modified: 12 Nov 2021<br>
        INTRODUCTION<br>
        We value your privacy. This Privacy Policy informs you of your choices and our practices regarding any Information you provide to us.<br>
        The use of our services may involve the collection and use of your Information (as defined in the “The Information We Collect and How We Collect It”section below) on our services (including our website: www.abqqs.com and mobile application: NFT MMM). It is important for you to understand how this happens and how you may control it, so please read this Privacy Policy carefully.<br>
        By using our services, you agree that we may collect, use and share your Information in accordance with this Privacy Policy, as revised from time to time.<br>
        If you wish to contact us regarding this Privacy Policy or any privacy-related matters, please contact our Privacy Officer via email at yelq@abqqs.com or via postal mail at 1/F Union Industrial Building, 116 Wai Yip Street, Kwun Tong, Hong Kong (Attention: Privacy Officer, Legal Department).<br>
        SCOPE OF THIS PRIVACY POLICY<br>
        This Privacy Policy applies across all of our services as defined above.<br>
        This Privacy Policy is incorporated into and form part of the NFT MMM Terms of Service that you have agreed to in order to use our services. Any terms used in this Privacy Policy will have the same meaning as the equivalent defined terms in the NFT MMM Terms of Service, unless otherwise defined in this Privacy Policy or the context requires otherwise.<br>
        Please also note that this Privacy Policy does not apply to Information collected: (i) through third party services (including any third party websites) that you may access through our services; or (ii) by other companies and organizations who advertise their services on our services.<br>
        CHANGES TO THIS PRIVACY POLICY<br>
        We may from time to time revise or add specific instructions, policies and terms to this Privacy Policy. These instructions, policies and terms form part of this Privacy Policy.<br>
        Where we consider that any changes to this Privacy Policy are reasonably material, we will notify you (via our http://www.abqqs.com ), direct communication to you, or other means) prior to the change becoming effective. By continuing to use our services after any changes to this Privacy Policy, with or without notice from us, you are agreeing to the revised Privacy Policy.<br>
        THE INFORMATION WE COLLECT AND HOW WE COLLECT IT<br>
        In providing our services, we collect, store and use the following Information relating to you:<br>
        “Information” is Personal Information and Non-Personal Information;<br>
        "Personal Information" is any information or combination of information that relates to you, and can be used to identify you. Personal Information may include the following:<br>
        information you make available to us when you open an account or use our services, such as your name, telephone number and email address;information which you make available to our services as you use them, including Shared Information you make available to others through our services and information you store using our services;Shared Information that others using our services make available about you, such as information contained in posts they make and communications they make to you and others using our services; andinformation we collect as you use our services, such as certain Location Data and Log Data.“Location Data” is information that we collect regarding your location (when you use a location-enabled service), including:<br>
        the location of your device when you use our services, such as from the GPS, WiFi, compass, accelerometer or other sensors in your mobile device;the IP address of the device or internet service you use to access our services,other information made available by you or others that gives an indication as to where you are or have been located, such as account information that indicates where you are located and Shared Information you or others post indicating your location, such as any geotag information that is embedded in any photos you make available to us; andif you have provided us with your location data previously and would like us to delete such existing location data, you may be able to do so via our services."Log Data" is technical information that is automatically collected by us when you use our services, whether through the use of cookies, web beacons and log files (as explained in more detail in the “Cookies, Web Beacons and Log Files” section below) or otherwise, including:<br>
        technical information such as your mobile carrier, configuration information made available by your web browser or other programs you use to access our services, your IP address and your device’s version and identification number,information about what you have searched for and looked at while using our services, such as web search terms used, social media profiles visited and details of other information and content accessed or requested by you in using our services;information about communications you have made using our services, such as the people you’ve communicated with and the time, data and duration of your communications; andmetadata, which means information related to items you have made available through our services, such as the date, time or location that a shared photograph or video was taken or posted.“Non-Personal Information” is any information that relates to you but from which it is not practicable to directly or indirectly identify you, including Personal Information in aggregated, anonymised or pseudonymised form.<br>
        “Shared Information” is information about you or relating to you that is voluntarily shared on our services, including postings that you make on our services (including your public profile, the lists you create), any postings from others that you re-post and including Location Data and Log Data associated with these postings. Shared Information also includes information about you (including Location Data and Log Data) that others who are using our services share about you.<br>
        HOW WE USE YOUR INFORMATION<br>
        We may use your Information for any of the following purposes:<br>
        to provide our services to you;for customer service, security, fraud-detection, archival and backup purposes in connection with the provision of our services;to provide you with advertising and direct marketing is more relevant to you (as discussed in more detail below under the “Direct Marketing”section below);to better understand how you access and use our services, for the purposes of trying to improve our services and to respond to customer desires and preferences, including language and location customization, personalized help and instructions, or other responses to your and other customers’ usage of our services;to help us develop our new services and improve our existing services;to assess the effectiveness of and improve advertising and other marketing and promotional activities on or in connection with our services you’re your account is subscribed to an official account; andto allow you to participate in surveys about our products and services. If you participate in a survey, we may request certain personally identifiable information from you. Participation in these surveys is voluntary and you therefore have a choice whether or not to disclose this information. We use may use a third party service provider to conduct these surveys and such third party service provider will be prohibited from using your personally identifiable information for any other purpose.Please note that for the purposes of seeking to provide our users with a better experience, to improve our services or otherwise where you have consented, Personal Information collected through our site or service may, subject to user privacy controls (where available), be used by our other services (including in an aggregated or individualized manner).<br>
        For example, Personal Information collected during your use of one of our services may be used to suggest particular content that can be made available to you on another service or be used to try to present more relevant advertising to you in another service. You may also from time to time ask us to migrate or export your Personal Information contained in one of our services to another of our services, where such migration option is available.<br>
        If any of your Personal Information comprises Your Content (as defined in the NFT MMM Terms of Service), we and our affiliate companies may (subject to this Privacy Policy) use such Personal Information in accordance with the “Your Content” section of the NFT MMM Terms of Service.<br>
        COOKIES, WEB BEACONS AND LOG FILES<br>
        We may use cookies, web beacons and log files to collect, and store Information about you or relating to you. We further describe how we use cookies, log files and web beacons in our Cookies Policy.<br>
        SHARING OF YOUR PERSONAL INFORMATION<br>
        Other than as permitted under this Privacy Policy or as otherwise consented to by you, we will not transfer your Personal Information to any other third parties for their processing for any purposes.<br>
        We and our affiliate companies may share your Personal Information within our group of companies and with joint venture partners and third party service providers, contractors and agents (such as communication service providers who send emails or push notifications on our behalf, and mapping services providers who assists us and you with location data), in each case, for the purposes of: (i) providing our services to you; (ii) assisting us in carrying out the purposes set out under the “How We Use Your Information” section above; (iii) carrying out our obligations and enforcing our rights under the NFT MMM Terms of Service or this Privacy Policy; and/or (iv) help understand and improve our services. These third parties may be located, and use and store your Personal Information, outside of your jurisdiction for these purposes.<br>
        Where we or our affiliate companies do share your Personal Information with any third parties, we will use reasonable efforts to ensure that such third parties only use your Personal Information: (i) in compliance with this Privacy Policy; and (ii) subject to any other instructions we give them, including any appropriate confidentiality and security measures that we implement.<br>
        You consent to the third party use, sharing and transfer of your Personal Information (both inside and outside of your jurisdiction) as described in this section.<br>
        As we continue to develop our business, we or our affiliate companies may be acquired by a third party or re-organize our group structure. In these circumstances, it may be the case that your Information is transferred within our group and/or transferred to a third party who will continue to operate our services or similar services under either this Privacy Policy or a different privacy policy which will be notified to you. The party to which your Personal Information is transferred may be located, and use your Information, outside of your jurisdiction.<br>
        You agree that we or our affiliate companies may be required to retain, preserve or disclose your Personal Information: (i) in order to comply with applicable laws or regulations; (ii) in order to comply with a court order, subpoena or other legal process; (iii) in response to a request by a government authority, law enforcement agency or similar body (whether situated in your jurisdiction or elsewhere); or (iv) where we believe it is reasonably necessary to comply with applicable laws or regulations. You also agree that we or our affiliate companies may need to disclose your Personal Information in order to enforce the NFT MMM Terms of Service or this Privacy Policy, protect our rights, property or safety, or the rights, property or safety of our affiliate companies or other users of our services.<br>
        A WORD ABOUT SHARED INFORMATION<br>
        Our service enable Shared Information to be shared publicly with all users of that service and not just your contacts. Our social media services in particular are primarily designed to help you share Shared Information with the world via features either provided by us or third parties, so Shared Information can be broadly and instantly transmitted and will remain public for as long as you do not delete it (and even after you delete Shared Information, it may still be separately cached, copied, or stored by, or remain public through, other users or third parties who are not affiliated with and not controlled by us).<br>
        Please consider carefully what you post and communicate through our services. In some instances, you may control what access the wider public has to your Shared Information via the privacy settings in some of our services. If you would like to update or amend your Personal Information you may do so by logging into your account and making the appropriate changes or by contacting our Privacy Officer. To request removal of your Personal Information from our services, or to request the removal of an unauthorized account, please contact our Privacy Officer. In some cases, we may not be able to update, amend or remove your Personal Information, in which case we will let you know why we are unable to do so.<br>
        COMMUNICATIONS FROM US<br>
        Newsletters and other types of communications<br>
        When you use our service, we may use your Information to send you emails, newsletters or push notifications to your device. If you no longer wish to receive these communications, you can do so by following the unsubscribe instructions in the emails we send to you, opting out of notifications at the device level (if available) or contacting our Privacy Officer via the contact information within this Policy.<br>
        Service-related announcements<br>
        We may from time to time send you service-related announcements when we consider it necessary to do so (such as when we temporarily suspend one of our services for maintenance). You may not opt-out of these service-related announcements, which are not promotional in nature.<br>
        A WORD ABOUT SENSITIVE PERSONAL INFORMATION<br>
        In some jurisdictions, certain Personal Information, such as information about your race or ethnic origin, religious or philosophical views or personal health, is characterised as “sensitive” and is subject to stricter regulation than other personal information. Please note that content and information that you input to our services, such as photographs or information about your school or social activities, may reveal your sensitive Personal Information to others.<br>
        We urge you to consider whether it is appropriate to communicate Personal Information of a sensitive nature in the manner you are proposing to communicate it using our services.<br>
        You consent to all processing of sensitive Personal Information for the purposes and in the manner described in this Privacy Policy.<br>
        Please note that we do not use your sensitive Personal Information for the purpose of serving advertising to you.<br>
        THIRD PARTY SERVICES ON OUR SERVICES<br>
        Our service may link you to, social media or other services (including websites) provided by a third party. For example:<br>
        you may be able to share content on, or login to, our services using services provided by third parties. The third parties who provide such services may be able to collect your Information and Personal Information (including your Log Data), and may set cookies on your computer to enable such feature to function properly; andwe may (whether in advertisements or otherwise within our services) provide you with links that allow you to access third party services or websites.These third party social media or other services may be hosted by the relevant third party or us. Your use of any such third party services (whether social media services or otherwise), including any Personal Information you provide to such third parties, are subject to the relevant third party’sown terms of services and privacy policies and not the NFT MMM Terms of Service or this Privacy Policy, so please review their terms carefully. This Privacy Policy only applies to any Information collected by us, does not apply to any services offered by or information practices of any third parties, and we bear no liability for any third party use of any Information provided by you to them.<br>
        AGE RESTRICTIONS<br>
        Children under the age of 13 are not allowed to use our services. We do not knowingly collect Personal Information from any children under the age of13. Please contact our Privacy Officer if you believe we have any Personal Information from any children under the age of 13 and we will promptly investigate (and remove) such Personal Information.<br>
        TRANSFER, STORAGE AND SECURITY OF YOUR PERSONAL INFORMATION<br>
        We operate and may continue to operate servers in a number of jurisdictions around the world, so the server on which your Personal Information is used and stored may not be in your jurisdiction.<br>
        You consent to the transfer of your Personal Information (both inside and outside of your jurisdiction) for the purposes described in this Privacy Policy.<br>
        We use a variety of security technologies and procedures for the purpose of preventing loss, misuse, unauthorised access or disclosure of Information.<br>
        In our service, we will use encryption technology (such as SSL) to protect certain sensitive Information (such as location data) provided by you to us. Please be aware that despite our efforts, no data security measures can guarantee 100% security at all times. Our systems and the communications networks through which you access our services may be subject to security breaches and failures which are due to circumstances beyond our reasonable control. If you have any questions regarding the security of your Personal Information, please contact our Privacy Officer.<br>
        PERIOD OF USE OF YOUR PERSONAL INFORMATION<br>
        Subject to applicable laws and regulations (including as set out under the “Sharing of your Personal Information” section above), we will only use your Personal Information for so long as is necessary to fulfil the purposes as set out under the “How We Use Your Information” section above.<br>
        Subject to applicable laws and regulations, you may have the right to request to receive a copy of and make any corrections to your Personal Information which we hold, and to request that we deactivate and not use any of your Personal Information that is stored by us. You may direct such request to our Privacy Officer, or where we offer this option in our services, you may also log into your account with us and make the appropriate changes. We will respond to all requests made to our Privacy Officer under this “Retention of your Personal Information” section within 60 days.<br>
        Should your account be terminated by you or us for any reason, we will (subject to the above paragraph) take steps to ensure that your Personal Information is no longer available through our services, or otherwise used by us, within a reasonable period of time (subject to technical limitations) after such account termination. However, it is important to note that communications made by you using our services may put your Personal Information in the hands of third parties that we cannot control.<br>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    }
}
</script>
<style scoped>
.text_style{
    padding: 10px;
    font-size: 15px;
    line-height: 24px;
    white-space: pre-wrap;
}
</style>