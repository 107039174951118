<template>
    <div class="outer">
        <div class="decorate">
            <div class="decorate_left">
                <div class="switch__circle"></div>
                <div class="logo">
                    <img src="@/assets/logo.png" alt="">
                </div>
                <p class="decorate_left_title">NFT {{$t('Movie')}}</p>
                <p class="decorate_left_desc">{{$t('Down load link')}}</p>
                <el-popover placement="bottom" width="140" trigger="hover">
                    <div class="app_code" ref="appCodeUrl"></div>
                    <el-button slot="reference" type="text" icon="el-icon-download" @click="download">Android app</el-button>
                </el-popover>
            </div>
            <div class="decorate_right">
                <div class="title">{{$t('Sweep the login code')}}</div>
                <div class="qr_code" ref="qrCodeUrl"></div>
                <p class="decorate_text">app {{$t('Sweep the login code')}}</p>
                <div class="right_btn">
                    <el-button type="text" style="margin-right:40px" icon="el-icon-service" @click="jump">{{$t('Contact Us')}}</el-button>
                    <el-button type="text" icon="el-icon-edit" @click="()=>{showAgreement = true}">《{{$t('the Services and Privacy Terms')}}》</el-button>
                </div>
            </div>
        </div>
        <el-dialog
            :title="$t('the Services and Privacy Terms')"
            top="5vh"
            :visible.sync="showAgreement">
            <div>
                <Agreement-en v-if="language === 'en'"></Agreement-en>
                <Agreement-cn v-else></Agreement-cn>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showAgreement = false">{{$t('Cancel')}}</el-button>
                <el-button type="primary" @click="showAgreement = false">{{$t('confirm')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import AgreementCn from '@/components/AgreementCn.vue'
import AgreementEn from '@/components/AgreementEn.vue'
export default {
    components:{AgreementCn, AgreementEn},
    data(){
        return{
            id:"",
            timer: '',
            showAgreement:false,
            language:"en",
            appurl:""
        }
    },
    methods:{
        init(){
            this.language = window.localStorage.getItem("language")
        },
        getCode(){
          this.$fetch(this.$center_url+"/get_id").then((res)=>{
              this.id = res.data;
              let createText = {
                  type:0,
                  data:res.data
              }
              new QRCode(this.$refs.qrCodeUrl, {
                text: JSON.stringify(createText),
                width: 200,
                height: 200,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
              })
          })
        },
        getLoginType(){
            this.$fetch(this.$center_url+"/v1/account/login/get_log?id="+this.id).then((res)=>{
                if(res.code === "ok"){
                    window.localStorage.setItem("pcLocalAccount",JSON.stringify(res.data))
                    this.$router.push("/")
                }
            })
        },
        download(){
            this.$fetch(this.$center_url+"/v1/app?type=android").then((res)=>{
                this.appurl = res.data.downloadUrl;
                new QRCode(this.$refs.appCodeUrl, {
                    text: this.appurl,
                    width: 120,
                    height: 120,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            })
        },
        jump(){
            this.$router.push("/About")
        }
    },
    mounted(){
        this.getCode()
        this.download()
        this.init()
        this.timer = setInterval(this.getLoginType, 2500);
    },
    beforeDestroy(){
        clearInterval(this.timer)
    }
}
</script>
<style lang="less" scoped>
@import url("./index.less");
</style>